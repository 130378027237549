import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { aliases, mdi } from 'vuetify/iconsets/mdi'

export default createVuetify({
  components,
  directives,

  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },

  theme: {
    themes: {
      dark: {
        colors: {
          'gray': '#999999',
          'primary': '#7876ff',
          'primary-navigation': '#1C1E24',
          'on-primary-container': '#bfbeff',
          'supplementary': '#8987F3',
          'surface': '#141218',
          'surface-container-low': '#191B1E',
          'surface-container': '#1C1E24',
          'on-surface': '#E6E0E9',
          'on-surface-container-low': '#E6E0E9',
          'on-surface-container': '#E6E0E9',
        },
      },
      light: {
        colors: {
          'gray': '#999999',
          'primary': '#24227C',
          'primary-navigation': '#24227C',
          'on-primary-container': '#171655',
          'supplementary': '#8987F3',
          'surface': '#FFFFFF',
          'surface-container-low': '#F2F3FA',
          'surface-container': '#F1F2F8',
          'on-surface': '#1D1B20',
          'on-surface-container-low': '#1D1B20',
          'on-surface-container': '#1D1B20',
        },
      },
    },
  },

  defaults: {
    VBtn: {
      color: 'primary',
      variant: 'flat',
    },

    VBtnGroup: {
      rounded: 'lg',
    },

    VCard: {
      class: 'page-panel',
      color: 'surface',
      variant: 'flat',
    },

    VProgressLinear: {
      color: 'primary',
    },

    VRadio: {
      color: 'primary',
    },

    VSelect: {
      color: 'primary',
      rounded: 'lg',
      variant: 'outlined',
    },

    VTextarea: {
      color: 'primary',
      rounded: 'lg',
      variant: 'outlined',
    },

    VTextField: {
      color: 'primary',
      rounded: 'lg',
      variant: 'outlined',
    },
  },
})
